// import logo from './logo.svg';
import './App.css';
import { AboutSection } from './sections/AboutSection';
import { IntroSection } from './sections/IntroSection';
// import { ProjectsSection } from './sections/ProjectsSection';
import { WorkExperienceSection } from './sections/WorkExperienceSection';
import { SidebarNav } from './sections/sidebars/SidebarNav';
import { SidebarMail } from './sections/sidebars/SidebarMail';
import { Footer } from './sections/Footer';
import { MainProjects } from './sections/projects/MainProjects';
import { OtherProjects } from './sections/projects/OtherProjects';

function App() {
  return (
    <div className='App'>
      <SidebarNav />
      <div className='Content'>
        <IntroSection />
        <AboutSection />
        <WorkExperienceSection />
        <MainProjects />
        <OtherProjects />
        <Footer />
      </div>
      <SidebarMail />
    </div>
  );
}

export default App;
