
import React from 'react';

import './MainProjects.css'

import { Fade } from "react-awesome-reveal";

import GitHubIcon from '@mui/icons-material/GitHub';
import Button from '@mui/material/Button';
import Background1 from '../../content/project1prev.png';
import Background2 from '../../content/project2prev.png';

const isMobile = window.innerWidth < 600;

export const MainProjects = () => {

    const mainProjects = {
        project1: {
            name: "DFIC Quant Team",
            org: "McMaster U",
            description: "Developed algorithmic trading strategies as part of the DFIC quant team. Personally focused on statistical arbitrage and event driven strategies.",
            link: "https://github.com/DFIC-Quant-Fund",
            tech: ["Python", "pandas_ta", "numpy"],
            img: Background1,
        },
        project2: {
            name: "Personal Website",
            org: "glotov.com",
            description: "Designed and developed this website as part of my fullstack learning journey. Collaborated with professional developers to gain valuable feedback.",
            link: "https://github.com/OlegGlo/glotov.com",
            tech: ["React.js", "CSS", "HTML5"],
            img: Background2,
        }
    }

    // MOBILE

    if (isMobile) {
        return (
            <section className='mainProjectsBackground'>
                <div className='mainProjectsFocus'>
                    <div className='mainProjectsFocusTitle'>
                        <h1 className='sectionNumber'> 03.</h1>
                        <h2 className='sectionTitle'> Projects</h2>
                    </div>
                    <div className='mainProjectsFocusGrid'>
                        {Object.keys(mainProjects).map((key, i) => (
                            
                            // <Fade direction='up' delay={100+i*250}>
                            
                            <div className='mainProjectsItem' key={i}>
                                <div className='mainProjectsImage1' style={{ backgroundImage: "url(" + mainProjects[key]["img"] + ")" }}></div>
                                <div className="mainProjectsOverlay">
                                    <p className='mainProjectsBigBlockTextOrgName fontSpaceMono'>{mainProjects[key]["org"]}</p>
                                    <h1 className='mainProjectsBigBlockTextProjName fontSpaceMono'>{mainProjects[key]["name"]}</h1>
                                    <p className='mainProjectsBigBlockTextProjDecrib'>{mainProjects[key]["description"]}</p>
                                    <Button
                                        variant="outlined"
                                        endIcon={<GitHubIcon />}
                                        size="large"
                                        color="secondary"
                                        sx={{ 
                                            color: 'white', 
                                            borderColor: 'white',
                                            bgcolor: 'black', 
                                            ':hover': { borderColor: 'yellowgreen', color: 'yellowgreen', bgcolor: 'black'}, 
                                            height: "44px", 
                                            borderRadius: "22px",
                                            marginLeft: "3%", }}
                                        href={mainProjects[key]["link"]}
                                        target="_blank"
                                    >
                                        VIEW
                                    </Button>
                                    <div className='mainProjectsBigBlockTextTechBubblesSection'>
                                        {Object.keys(mainProjects[key]["tech"]).map((key2, k) => (
                                            <div className='mainProjectsBigBlockTextTechBubble' key={k}>
                                                <p className='techBubble fontSpaceMono'>{mainProjects[key]["tech"][key2]}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
    
                            // </Fade>
                        ))}
                    </div>
                </div>
            </section>
        )
    } else { //DESKTOP
        return (
            <section className='mainProjectsBackground'>
                <div className='mainProjectsFocus'>
                    <div className='mainProjectsFocusTitle'>
                        <h1 className='sectionNumber'> 03.</h1>
                        <h2 className='sectionTitle'> Projects</h2>
                    </div>
                    <div className='mainProjectsFocusGrid'>
                        {Object.keys(mainProjects).map((key, i) => (
                            
                            <Fade direction='up' delay={100+i*250} triggerOnce={false}>
                            
                            <div className='mainProjectsItem' key={i}>
                                <div className='mainProjectsImage1' style={{ backgroundImage: "url(" + mainProjects[key]["img"] + ")"}}></div>
                                {/* <div className='mainProjectsImage1' style={{ background: "rgba(10,10,10,1)"}}></div> */}
                                <div className="mainProjectsOverlay">
                                    <p className='mainProjectsBigBlockTextOrgName fontSpaceMono'>{mainProjects[key]["org"]}<span class="chevron top"></span></p>

                                    

                                    <h1 className='mainProjectsBigBlockTextProjName fontSpaceMono'>{mainProjects[key]["name"]}</h1>
                                    <p className='mainProjectsBigBlockTextProjDecrib'>{mainProjects[key]["description"]}</p>
                                    <Button
                                        variant="outlined"
                                        endIcon={<GitHubIcon />}
                                        size="large"
                                        color="secondary"
                                        sx={{ 
                                            color: 'white', 
                                            borderColor: 'white',
                                            bgcolor: 'black', 
                                            ':hover': { borderColor: 'yellowgreen', color: 'yellowgreen', bgcolor: 'black'}, 
                                            height: "44px", 
                                            borderRadius: "22px",
                                            marginLeft: "3%", }}
                                        href={mainProjects[key]["link"]}
                                        target="_blank"
                                    >
                                        VIEW
                                    </Button>
                                    <div className='mainProjectsBigBlockTextTechBubblesSection'>
                                        {Object.keys(mainProjects[key]["tech"]).map((key2, k) => (
                                            <div className='mainProjectsBigBlockTextTechBubble' key={k}>
                                                <p className='techBubble fontSpaceMono'>{mainProjects[key]["tech"][key2]}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
    
                            </Fade>
                        ))}
                    </div>
                </div>
            </section>
        )
    }
}