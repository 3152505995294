
import React from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import './IntroSection.css'
import resume from '../content/Oleg_Glotov_resume.pdf';

import Spline from '@splinetool/react-spline';

import { Fade } from "react-awesome-reveal";

const isMobile = window.innerWidth < 600;

// experimental
// import useMediaQuery from '@mui/material/useMediaQuery';

export const IntroSection = () => {

    // const writeup = {
    //     intro : "I am a third-year student at McMaster university with a major in software engineering and a minor in finance. My primary interest lies within the financial technology sphere, with areas such as algorithmic trading and big data analysis."
    // }

    // for size checking
    // const matches = useMediaQuery('(min-width:600px)');

    return (
        <section className='introBackground'>
            <div className='introContentSection'>
                <div className='sectionNumber fontSpaceMono' id='myName'> Hi, my name is</div>
                <h1 className='introName fontHelveticaNeue'> Oleg Glotov </h1>
                <h2 className='introQuote fontHelveticaNeue'> Welcome to my portfolio </h2> {/* I build stuff sometimes */}
                <p className='introWriteup'> I am a fourth-year student with a major in <span className="introHighlight">software engineering</span> and a <span className="introHighlight">minor in finance</span> at McMaster university. Currently part of DFIC quant finance team. </p>
                <Fade delay={(isMobile) ? 400 : 400} triggerOnce={true}>
                    <Button
                        // onClick={refreshPage}
                        href={resume}
                        // target="blank"
                        variant="contained"
                        endIcon={<DownloadIcon />}
                        sx={{
                            color: "black",
                            backgroundColor: "yellowgreen",
                            height: "70px",
                            width: "200px",
                            borderRadius: "35px",
                            fontSize: "16px",
                            letterSpacing: "3px",
                            '&:hover': { backgroundColor: "white" },
                            // position: 'absolute',
                            // bottom:'20%',
                        }}
                    >
                        Resume
                    </Button>
                </Fade>
            </div>
            <div className='introSplineSection'>
                <Spline scene="https://prod.spline.design/kaqR3bd5tHhm-nNc/scene.splinecode" />
                {/* <iframe title='spline' src='https://my.spline.design/f1key-a15d8c9d39c9e6ad5aff6270a4f45ad0/' frameborder='0' width='100%' height='100%'></iframe> */}
            </div>
        </section>
    )
}