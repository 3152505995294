
import React from 'react';
import './WorkExperienceSection.css'

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Fade } from "react-awesome-reveal";

//styling
// import { makeStyles } from '@mui/styles/makeStyles';

const isMobile = window.innerWidth < 900;
// const isMobile = true;

//Controls the appearance - link: https://mui.com/material-ui/react-tabs/#main-content
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {/* <Box> */}
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
//another pre-made helper
// function a11yProps(index) {
//     return {
//         id: `vertical-tab-${index}`,
//         'aria-controls': `vertical-tabpanel-${index}`,
//     };
// }

function a11yProps(index) {
    if (isMobile) {
      return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`
      };
    } else {
      return {
        id: `vertical-tab-${index}`
      };
    }
}

//styling guide
// const useStyles = makeStyles(theme => ({
//     root: {
//         flexGrow: 1,
//         backgroundColor: "theme.palette.background.paper",
//         display: "flex",
//         height: 300
//     },
//     tabs: {
//         borderRight: `1px solid ${theme.palette.divider}`
//     }
// }));

export const WorkExperienceSection = () => {

    //styling from above
    // const classes = useStyles();

    const jobs = {
        "BMO Capital Markets": {
            role: "Quantitative Analyst - Cross Asset Derivatives",
            time: "December 2023 – August 2024",
            workdone: [
                "Implemented and deployed a full-stack Performance Attribution Analysis application utilizing PostgreSQL, Flask (Python), and React.js used by Canadian and US based teams",
                "Produced daily sensitivity reports for existing product baskets to assess their sensitivity to multiple market shock scenarios",
                "Improved and automated the quarterly production of a comprehensive report on option risk hedges' performance using VBA",
                "Contributed to the design of new database pipelines and clustering methods, optimizing data processing for trading floor teams, resulting in a 40% improvement in average query speed",
            ],
        },
        "Sberbank CIB": {
            role: "Data Science Intern",
            time: "June 2022 – August 2022",
            workdone: [
                "Built credit risk models using logistic regression, random forest, and XGBoost to improve default prediction accuracy",
                "Developed a Python package for data cleaning interpretation, streamlining data preprocessing and enhancing analysis efficiency",
                "Collaborated with the data engineering team to deploy machine learning models into production, enabling team-wide utilization",
            ],
        },
        "TMK Group": {
            role: "Summer Intern - Software and Testing Team",
            time: "June 2021 – August 2021",
            workdone: [
                "Remodelled oil and natural gas pipeline threaded connections in the Abaqus FEA to allow for more efficient stress testing simulations",
                "Optimized Python simulation scripts resulting in a 10% decrease in computation time while allowing better interactivity for the engineering and design teams during all testing phases",
                "Co-drafted a complete documentation of the simulation scripts used in the testing process",
            ],
        },
    }

    const jobsMobile = {
        "BMO - CM": {
            role: "Quant Dev",
            time: "Winter - Summer 2024",
            workdone: [
                "Implemented and deployed a full-stack Performance Attribution Analysis application used by Canadian and US based teams",
                "Produced daily sensitivity reports for existing product baskets to assess their sensitivity to multiple market shock scenarios",
                "Improved and automated the quarterly production of a comprehensive report on option risk hedges' performance using VBA",
                "Contributed to the design of new database pipelines and clustering methods, optimizing data processing for trading floor teams",
            ],
        },
        "Sberbank CIB": {
            role: "DS Intern",
            time: "Summer 2022",
            workdone: [
                "Built credit risk models using logistic regression, random forest, and XGBoost to improve default prediction accuracy",
                "Developed a Python package for data cleaning interpretation, streamlining data preprocessing and enhancing analysis efficiency",
                "Collaborated with the data engineering team to deploy machine learning models into production, enabling team-wide utilization",
            ],
        },
        "TMK Group": {
            role: "SWE Intern",
            time: "Summer 2021",
            workdone: [
                "Remodeled existing oil and natural gas pipeline threaded connections using the Abaqus FEA to allow more efficient stress testing simulations",
                "Improved existing Python simulation scripts to allow better interactivity for the engineering and design team during all phases of testing",
                "Co-drafted a complete documentation of the simulation scripts used in the testing process",
            ],
        },
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (isMobile) { //MOBILE
        return (
            <section className='workBackground'>
                <div className='workContent'>
                    <div className='workSectionTitle'>
                        <h2 className='sectionNumber'>02.</h2>
                        <h1 className='sectionTitle'>Work Experience</h1>
                        {/* <p>{isMobile ? "on mobile" : "not on mobile"}</p> */}
                    </div>
                    <div className='workSelector'>
                        <Box
                            sx={{
                                // flexGrow: 1, 
                                // bgcolor: 'background.paper', 
                                // display: 'flex', 
                                // height: 224,
    
                                flexGrow: 1,
                                bgcolor: 'yellowgreen',
                                display: 'flex',
                                flexDirection: 'column'
                                // height: "40%",
                                // height: "524px",
                                // bgcolor: 'background.paper', 
                                // width: "",
                            }}
                        >
                            <Tabs
    
                                // style={{minWidth:"160px"}}
                                TabIndicatorProps={{
                                    sx: {
                                        backgroundColor: 'black',
                                        // height: 80,
                                    },
                                }}
    
    
                                
                                orientation="vertical"
                                variant="fullWidth"
                                // variant="fullWidth"
                                value={value}
                                onChange={handleChange}
                                textColor="inherit"
                                sx={{
                                    // borderRight: 1,
                                    // borderColor: 'divider',
                                    // width: "150px",
                                    borderRight: 1,
                                    borderColor: 'divider',
                                    // background:"black",
                                    minWidth: '160px',
    
                                }}
                            >
                                {/* loops through the possible tabs */}
                                {Object.keys(jobsMobile).map((key, i) => (
                                    <Tab label={key} {...a11yProps(i)}></Tab>
                                ))}
    
                                {/* testing for more items here */}
                                {/* <Tab label="Item One" {...a11yProps(0)} /> */}
                                {/* <Tab label="Item Two" {...a11yProps(1)} /> */}
                            </Tabs>
                            {Object.keys(jobsMobile).map((key, i) => (
                                //outputs the panel
                                <TabPanel className="workSectionJobBlock" value={value} index={i} sx={{ fontSize: "left" }}>
                                    {/* <span className="workSectionJobTitle"> {jobs[key]["role"] + " @ "}</span> <span className="workSectionJobTitle" >{key}</span> */}
                                    <span className="workSectionJobTitle"> {jobsMobile[key]["role"] + " @ " + key}</span>
                                    <div className="workSectionJobTime">
                                        {jobsMobile[key]["time"]}
                                    </div>
                                    <ul>
                                        {jobsMobile[key]["workdone"].map((key, i) => (
                                            <Fade direction='up' delay={100+i*100}>
                                                <li key={i}>{key}</li>
                                            </Fade>
                                        ))}
                                    </ul>
                                </TabPanel>
                            ))}
                        </Box>
                    </div>
                </div>
            </section>
        )
    } else { //DESKTOP
        return (
            <section className='workBackground'>
                <div className='workContent'>
                    <div className='workSectionTitle'>
                        <h2 className='sectionNumber'>02.</h2>
                        <h1 className='sectionTitle'>Work Experience</h1>
                        {/* <p>{isMobile ? "on mobile" : "not on mobile"}</p> */}
                    </div>
                    <div className='workSelector'>
                        <Box
                            sx={{
                                // flexGrow: 1, 
                                // bgcolor: 'background.paper', 
                                // display: 'flex', 
                                // height: 224,
    
                                flexGrow: 1,
                                bgcolor: 'yellowgreen',
                                display: 'flex',
                                // height: "40%",
                                // height: "524px",
                                // bgcolor: 'background.paper', 
                                // width: "",
                            }}
                        >
                            <Tabs
    
                                // style={{minWidth:"160px"}}
                                TabIndicatorProps={{
                                    sx: {
                                        backgroundColor: 'black',
                                        // height: 80,
                                    },
                                }}
    
                                orientation="vertical"
                                variant="scrollable"
                                // variant="fullWidth"
                                value={value}
                                onChange={handleChange}
                                textColor="inherit"
                                sx={{
                                    // borderRight: 1,
                                    // borderColor: 'divider',
                                    // width: "150px",
                                    borderRight: 1,
                                    borderColor: 'divider',
                                    // background:"black",
                                    minWidth: '160px',
    
                                }}
                            >
                                {/* loops through the possible tabs */}
                                {Object.keys(jobs).map((key, i) => (
                                    <Tab label={key} {...a11yProps(i)}></Tab>
                                ))}
    
                                {/* testing for more items here */}
                                {/* <Tab label="Item One" {...a11yProps(0)} /> */}
                                {/* <Tab label="Item Two" {...a11yProps(1)} /> */}
                            </Tabs>
                            {Object.keys(jobs).map((key, i) => (
                                //outputs the panel
                                <TabPanel className="workSectionJobBlock" value={value} index={i} sx={{ fontSize: "left" }}>
                                    {/* <span className="workSectionJobTitle"> {jobs[key]["role"] + " @ "}</span> <span className="workSectionJobTitle" >{key}</span> */}
                                    <span className="workSectionJobTitle"> {jobs[key]["role"] + " @ " + key}</span>
                                    <div className="workSectionJobTime">
                                        {jobs[key]["time"]}
                                    </div>
                                    <ul>
                                        {jobs[key]["workdone"].map((key, i) => (
                                            <Fade direction='left' delay={100+i*150}>
                                                <li key={i}>{key}</li>
                                            </Fade>
                                        ))}
                                    </ul>
                                </TabPanel>
                            ))}
                        </Box>
                    </div>
                </div>
            </section>
        )
    }

}