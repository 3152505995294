
import React from 'react';

import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";

import GitHubIcon from '@mui/icons-material/GitHub';
import LinkIcon from '@mui/icons-material/Link';

import './OtherProjects.css'

import { Fade } from "react-awesome-reveal";

const isMobile = window.innerWidth < 600;

export const OtherProjects = () => {

    const projects = {
        "TeachAI": {
            desc:
                "An AI driven lesson planner framework created at DeltaHacks 9.",
            techStack: "React, OpenAI, node.js",
            link: "https://github.com/OlegGlo/teachAI",
            open: "https://teach-ai.netlify.app/"
        },
        "Web Scraper Tool": {
            desc:
                "An application created for personal use to check an online store for product restocks",
            techStack: "Python, Selenium, Bash/UNIX",
            link: "https://github.com/OlegGlo/ikea-web-scraper",
            // open: "https://tdsb-app.herokuapp.com/"
        },
        "Telegram Message Bot": {
            desc:
                "An interactive bot created as a learning project to explore AWS and Telegram API infrastructure",
            techStack: "Python, Telegram API, SQL",
            link: "https://github.com/OlegGlo/telegram-message-bot",
            // open: "https://gazijarin.github.io/AdamAI/",
        },
        "Discord Server Management System": {
            desc:
                "A system that assists server owners in managing and processing users support tickets and other interactivity.",
            techStack: "Python, Discord API, Bash/UNIX",
            link:
                "https://github.com/404",
        },
        "Bulk SMS sender": {
            desc:
                "A Python script that automates the sign up process for a wide variety of third party services.",
            techStack: "Python, PySocks",
            link: "https://github.com/404",
            open: ""
        },
        // "Game Centre": {
        //     desc:
        //         "An Android app consisting of three board games, including multiplayer, autosave, user authentication, etc.",
        //     techStack: "Java, Android Studio",
        //     link: "https://github.com/gazijarin/gamecentre",
        //     open: ""
        // },
        // "Minimax Stonehenge": {
        //     desc:
        //         "Two-player, zero-sum game with a strategic Minimax artificial intelligence.",
        //     techStack: "Python",
        //     link: "https://github.com/gazijarin/stonehenge",
        //     open: ""
        // }
    };

    if (isMobile) {
        return (
            <section className='otherProjectsBackground'>
                <div className='otherProjectsFocus'>
                    <div className='otherProjectsFocusTitle'>
                        <h1 className='sectionNumber'> 04.</h1>
                        <h2 className=' sectionTitle'> Other projects</h2>
                    </div>
                    <div className="project-container">
                        <ul className="projects-grid">
                            {Object.keys(projects).map((key, i) => (

                                // <Fade direction='up' triggerOnce={(isMobile) ? true : false} delay={(isMobile) ? 250 : 150 + i * 100} >
                                <li className="projects-card" key={i}>
                                    <div className="card-header">
                                        <div className="folder-icon">
                                            <FolderOpenRoundedIcon
                                                style={{ fontSize: 35 }}
                                            ></FolderOpenRoundedIcon>
                                        </div>
                                        <a className='github-icon' style={{opacity:"1"}} href={projects[key]["link"]}>
                                            <GitHubIcon
                                                style={{ fontSize: 35 }}
                                            ></GitHubIcon>
                                        </a>
                                        {"open" in projects[key] ?
                                            <a className='link-icon' style={{opacity:"1"}} href={projects[key]["open"]}>
                                                <LinkIcon
                                                    style={{ fontSize: 35 }}
                                                ></LinkIcon>
                                            </a>
                                            :
                                            <></>
                                        }

                                        {/* <ExternalLinks
                                                githubLink={projects[key]["link"]}
                                                openLink={projects[key]["open"]}
                                            ></ExternalLinks> */}
                                    </div>
                                    <div className="card-title">{key}</div>
                                    <div className="card-desc">{projects[key]["desc"]}</div>
                                    <div className="card-tech">{projects[key]["techStack"]}</div>
                                </li>
                                // </Fade>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className='otherProjectsBackground'>
                <div className='otherProjectsFocus'>
                    <div className='otherProjectsFocusTitle'>
                        <h1 className='sectionNumber'> 04.</h1>
                        <h2 className=' sectionTitle'> Other projects</h2>
                    </div>
                    <div className="project-container">
                        <ul className="projects-grid">
                            {Object.keys(projects).map((key, i) => (

                                <Fade direction='up' triggerOnce={(isMobile) ? true : false} delay={(isMobile) ? 250 : 150 + i * 100} >
                                    <li className="projects-card" key={i}>
                                        <div className="card-header">
                                            <div className="folder-icon">
                                                <FolderOpenRoundedIcon
                                                    style={{ fontSize: 35 }}
                                                ></FolderOpenRoundedIcon>
                                            </div>
                                            <a className='github-icon' href={projects[key]["link"]}>
                                                <GitHubIcon
                                                    style={{ fontSize: 35 }}
                                                ></GitHubIcon>
                                            </a>
                                            {"open" in projects[key] ?
                                                <a className='link-icon' href={projects[key]["open"]}>
                                                    <LinkIcon
                                                        style={{ fontSize: 35 }}
                                                    ></LinkIcon>
                                                </a>
                                                :
                                                <></>
                                            }

                                            {/* <ExternalLinks
                                                githubLink={projects[key]["link"]}
                                                openLink={projects[key]["open"]}
                                            ></ExternalLinks> */}
                                        </div>
                                        <div className="card-title">{key}</div>
                                        <div className="card-desc">{projects[key]["desc"]}</div>
                                        <div className="card-tech">{projects[key]["techStack"]}</div>
                                    </li>
                                </Fade>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}
