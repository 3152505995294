
import React from 'react';
import './SidebarMail.css';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

import { Fade } from "react-awesome-reveal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SidebarMail = () => {

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText("oleg@glotov.com");
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button> */}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div className='navAreaMail'>
            <div className='mail'>
                {/* <p className='linkMail' onClick={() => { navigator.clipboard.writeText("oleg@glotov.com"); }}> oleg@glotov.com</p> */}
                <Fade direction='right' delay={2000}>
                    <p className='linkMail' onClick={handleClick}> oleg@glotov.com</p>
                </Fade>
                {/* <button className='linkMail' href="google.com">click</button> */}
                <Snackbar
                    className='snackbar'
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message="Email saved to clipboard"
                    action={action}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    severity="warning"
                >
                    <Alert onClose={handleClose} severity="info" sx={{ width: '100%', color:"black" ,backgroundColor: "white" }}>
                        Email copied to clipboard!
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}