
import React from 'react';
import './SidebarNav.css';

import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Fade } from "react-awesome-reveal";

//close snackbar stuff
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

//dialogue
import Dialog from '@mui/material/Dialog';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const isMobile = window.innerWidth < 600;

export const SidebarNav = () => {


    //snackbar stuff
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText("oleg@glotov.com");
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button> */}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
                // handle close
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    if (isMobile) { //mobile
        return (
            <div className='navAreaNav'>
                <div className='navElements'>

                    <div className='linkSocial'>
                        {/* below href="mailto:oleg@glotov.com" */}
                        <div className='linkIcon email' onClick={handleClick}>
                            <EmailIcon sx={{ fontSize: 35 }} />
                        </div>
                        {/* used to be <a> */}
                    </div>

                    {/* <Fade delay={800} direction='left'> */}
                    <div className='linkSocial'>
                        <a className='linkIcon linkedin' href="https://www.linkedin.com/in/olegglotov/">
                            {/* <LinkedInIcon sx={{ fontSize: 35, color: 'white' }} /> */}
                            <LinkedInIcon sx={{ fontSize: 35 }} />
                        </a>
                    </div>
                    {/* </Fade> */}
                    {/* <Fade delay={1200} direction='left'> */}
                    <div className='linkSocial'>
                        <a className='linkIcon github' href="https://github.com/OlegGlo">
                            <GitHubIcon sx={{ fontSize: 32 }} />
                        </a>
                    </div>
                    {/* </Fade> */}

                    <div className='linkSocial'>
                        <a className='linkIcon telegram' href="https://t.me/oleg_glo">
                            <TelegramIcon sx={{ fontSize: 35 }} />
                        </a>
                    </div>


                    <Dialog
                        open={open}
                        keepMounted
                        onClose={handleClose}
                    >

                        <Snackbar
                            className='snackbar'
                            open={open}
                            autoHideDuration={3000}
                            onClose={handleClose}
                            message="Email saved to clipboard"
                            action={action}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            severity="warning"
                        >
                            {/* <Alert onClose={handleClose} severity="info" sx={{ width: '100%', color: "yellowgreen", backgroundColor: "black" }}> */}
                            <Alert onClose={handleClose} severity="info" sx={{ width: '100%', color: "black", backgroundColor: "white" }}>
                                Email copied to clipboard!
                            </Alert>
                        </Snackbar>
                    </Dialog>

                </div>
            </div>
        )
    } else { //Desktop
        return (
            <div className='navAreaNav'>
                <div className='navElements'>
                    {/* <Slide delay={400}> */}
                    {/* <div className='email linkSocial'>
                        <a href="mailto:olegglotov99@gmail.com">
                            <EmailIcon sx={{ fontSize: 32, color: 'white' }} />
                        </a>
                    </div> */}
                    {/* </Slide> */}
                    <Fade delay={800} direction='left'>
                        <div className='linkSocial'>
                            <a className='linkIcon linkedin' href="https://www.linkedin.com/in/olegglotov/">
                                {/* <LinkedInIcon sx={{ fontSize: 35, color: 'white' }} /> */}
                                <LinkedInIcon sx={{ fontSize: 35 }} />
                            </a>
                        </div>
                    </Fade>
                    <Fade delay={1200} direction='left'>
                        <div className='linkSocial'>
                            <a className='linkIcon github' href="https://github.com/OlegGlo">
                                <GitHubIcon sx={{ fontSize: 32 }} />
                            </a>
                        </div>
                    </Fade>
                    <Fade delay={1600} direction='left'>
                        <div className='linkSocial'>
                            <a className='linkIcon telegram' href="https://t.me/oleg_glo">
                                <TelegramIcon sx={{ fontSize: 35 }} />
                            </a>
                        </div>
                    </Fade>
                </div>
            </div>
        )
    }


}